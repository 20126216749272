import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

import { Lead } from 'styles/typography'

import PageHeader from 'components/PageHeader'
import Section, { SectionBlock } from 'components/Section'
import MetaTags from 'components/MetaTags'
import Heading from 'components/Heading'
import Wrapper from 'components/Wrapper'
import RichText from 'components/RichText'
import ServiceGrid from 'components/ServiceGrid'
import Related from 'components/industries/Related'
import DesignToolkit from 'components/services/Toolkit/DesignToolkit'
import Showcase from 'components/services/Showcase'
import Explore from 'components/services/Explore'

const Strategy = ({ location }) => (
  <>
    <MetaTags title="Digital product strategy and consulting | Volume7" />

    <PageHeader
      breadcrumbs={[
        {
          label: 'Services',
          link: '/services/',
        },
        {
          label: 'Design',
        },
      ]}
      heading="Digital product design"
      description="We design user experiences that are intuitive and elegant, and translate them into working code that's pixel-perfect."
      figure={
        <StaticImage
          src="../assets/images/services/design.jpg"
          layout="fullWidth"
          alt=""
          placeholder="blurred"
        />
      }
    />

    <Section flushTop>
      <Wrapper size="narrow">
        <SectionBlock>
          <RichText>
            <Lead>
              In today's world, good design is crucial to a successful product
              and provides significant business value. A well-designed product
              balances usefulness, ease of learning, usability, <em>and</em>{' '}
              aesthetics.
            </Lead>

            <p>
              Our designers have experience designing at top technology
              companies, and have brought that expertise in-house. We approach
              your project with that same vigour, helping your business stand
              out from the competition through great design.
            </p>
          </RichText>
        </SectionBlock>
        <SectionBlock>
          <Heading size={3} tag="h2">
            Data-informed
          </Heading>
          <RichText>
            We leverage modern analytics tools and UX research to help us design
            more effective user experiences, while infusing our own style and
            following our instincts. We use data to inform our decisions, but
            also have conviction in our years of experience practicing the craft
            when finding creative solutions.
          </RichText>
        </SectionBlock>
        <SectionBlock>
          <Heading size={3} tag="h2">
            Prototypes not presentations
          </Heading>
          <RichText>
            <p>
              We focus our efforts on hands-on designing to deliver tangible
              solutions that directly respond to a problem. We won't waste
              resources building presentation decks or anything that doesn't
              provide immediate, concrete value.
            </p>
          </RichText>
        </SectionBlock>
      </Wrapper>
    </Section>

    <Showcase />

    <Section>
      <Wrapper size="narrow">
        <SectionBlock>
          <Heading size={3} tag="h2">
            Design for everywhere
          </Heading>
          <RichText>
            We consider different devices and screen sizes when working,
            ensuring that the interfaces we design effectively respond to the
            user's behavior and environment. We've designed software optimized
            for laptops, tablets, phones, smart watches, and even large-scale
            installations.
          </RichText>
        </SectionBlock>
        <SectionBlock>
          <Heading size={3} tag="h2">
            Designing systems
          </Heading>
          <RichText>
            We design user interfaces with scalability and reusability in mind,
            and consider componentizing from the start. This makes the front-end
            implementation process more efficient, and maximizes the value that
            can be extracted from our work, by allowing developers to reuse
            existing components to create new features.
          </RichText>
        </SectionBlock>
      </Wrapper>
    </Section>

    <Section hasBorder id="services">
      <Wrapper size="narrow">
        <ServiceGrid
          title="Services"
          services={[
            {
              name: 'User experience (UX) design',
              list: [
                'User research',
                'Wireframes',
                'Prototyping',
                'User testing',
              ],
            },
            {
              name: 'User interface (UI) design',
              list: [
                'Information architecture',
                'Visual design',
                'Design systems',
              ],
            },
            {
              name: 'Web design',
              list: ['Landing pages', 'Marketing websites', 'E-commerce'],
            },
            {
              name: 'Branding',
              list: ['Identity design', 'Brand guidelines', 'Style guides'],
            },
            {
              name: 'Data visualization',
              list: [
                'Custom charts',
                'Interactive visualizations',
                'Dashboards',
              ],
            },
          ]}
        />
      </Wrapper>
    </Section>

    <DesignToolkit />

    <Related projects={['aldo', 'usewalter']} />

    <Explore location={location} />
  </>
)

export default Strategy
