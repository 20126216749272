import React from 'react'

import Toolkit from 'components/services/Toolkit'

import Figma from 'assets/images/services/toolkit/figma.svg'
import Framer from 'assets/images/services/toolkit/framer.svg'
import Axure from 'assets/images/services/toolkit/axure.svg'
import Sketch from 'assets/images/services/toolkit/sketch.svg'
import Illustrator from 'assets/images/services/toolkit/illustrator.svg'
import Photoshop from 'assets/images/services/toolkit/photoshop.svg'
import UXPin from 'assets/images/services/toolkit/uxpin.svg'

const DesignToolkit = () => (
  <Toolkit
    heading="Tools of the trade"
    description="Deliverables include wireframes, design systems, interactive prototypes, and brand style guides."
    items={[
      {
        figure: <Figma />,
        label: 'Figma',
      },
      {
        figure: <Sketch />,
        label: 'Sketch',
      },
      {
        figure: <Framer />,
        label: 'Framer',
      },
      {
        figure: <Photoshop />,
        label: 'Photoshop',
      },
      {
        figure: <Illustrator />,
        label: 'Illustrator',
      },
      {
        figure: <Axure />,
        label: 'Axure',
      },
      {
        figure: <UXPin />,
        label: 'UXPin',
      },
    ]}
  />
)

export default DesignToolkit
