import React from 'react'
import styled from 'styled-components'
import { ThemeProvider } from 'styled-components'

import { themes } from 'styles/theme'

import Section, { SectionBlock } from 'components/Section'
import Wrapper from 'components/Wrapper'
import RichText from 'components/RichText'
import Heading from 'components/Heading'
import Gallery from 'components/work/Gallery'

const Container = styled.div`
  overflow: hidden;
`

const Showcase = () => (
  <ThemeProvider theme={themes.dark}>
    <Container>
      <Section id="showcase" flushBottom>
        <SectionBlock>
          <Wrapper size="narrow">
            <Heading size={3} tag="h2">
              Design showcase
            </Heading>
            <RichText>
              <p>
                Here's a small taste of what you can expect from working with
                us.
              </p>
            </RichText>
          </Wrapper>
        </SectionBlock>

        <SectionBlock>
          <Wrapper>
            <Gallery />
          </Wrapper>
        </SectionBlock>
      </Section>
    </Container>
  </ThemeProvider>
)

export default Showcase
