import React from 'react'
import styled from 'styled-components'
import { StaticImage } from 'gatsby-plugin-image'

import { borderRadius } from 'styles/variables'
import { cover } from 'styles/helpers'
import * as spacing from 'styles/spacing'
import { largerThan, smallerThan } from 'styles/media'

const ITEMS = [
  {
    photo: (
      <StaticImage
        src="../../assets/images/work/misc/telemedicine.png"
        layout="fullWidth"
        placeholder="blurred"
        alt=""
      />
    ),
  },
  {
    photo: (
      <StaticImage
        src="../../assets/images/work/misc/affirm-website.png"
        layout="fullWidth"
        placeholder="blurred"
        alt=""
      />
    ),
  },
  {
    photo: (
      <StaticImage
        src="../../assets/images/work/misc/walter-dashboard.jpg"
        layout="fullWidth"
        placeholder="blurred"
        alt=""
      />
    ),
  },
  {
    photo: (
      <StaticImage
        src="../../assets/images/work/misc/summed.jpg"
        layout="fullWidth"
        placeholder="blurred"
        alt=""
      />
    ),
  },
  {
    photo: (
      <StaticImage
        src="../../assets/images/work/misc/walter-app.jpg"
        layout="fullWidth"
        placeholder="blurred"
        alt=""
      />
    ),
  },
  {
    photo: (
      <StaticImage
        src="../../assets/images/work/misc/retailer-app.png"
        layout="fullWidth"
        placeholder="blurred"
        alt=""
      />
    ),
  },
  {
    photo: (
      <StaticImage
        src="../../assets/images/work/misc/summed-website.jpg"
        layout="fullWidth"
        placeholder="blurred"
        alt=""
      />
    ),
  },
  {
    photo: (
      <StaticImage
        src="../../assets/images/work/misc/stamp-mobile-app.jpg"
        layout="fullWidth"
        placeholder="blurred"
        alt=""
      />
    ),
  },
  {
    photo: (
      <StaticImage
        src="../../assets/images/work/misc/affirm-icons.png"
        layout="fullWidth"
        placeholder="blurred"
        alt=""
      />
    ),
  },
  {
    photo: (
      <StaticImage
        src="../../assets/images/work/misc/harvest-logo.png"
        layout="fullWidth"
        placeholder="blurred"
        alt=""
      />
    ),
  },
  {
    photo: (
      <StaticImage
        src="../../assets/images/work/misc/affirm-landing.png"
        layout="fullWidth"
        placeholder="blurred"
        alt=""
      />
    ),
  },
  {
    photo: (
      <StaticImage
        src="../../assets/images/work/misc/ftm-website.jpg"
        layout="fullWidth"
        placeholder="blurred"
        alt=""
      />
    ),
  },
  {
    photo: (
      <StaticImage
        src="../../assets/images/work/misc/aldo-volunteering.png"
        layout="fullWidth"
        placeholder="blurred"
        alt=""
      />
    ),
  },
  {
    photo: (
      <StaticImage
        src="../../assets/images/work/misc/sentilink-logo.jpg"
        layout="fullWidth"
        placeholder="blurred"
        alt=""
      />
    ),
  },
  {
    photo: (
      <StaticImage
        src="../../assets/images/work/misc/mentorly-website.png"
        layout="fullWidth"
        placeholder="blurred"
        alt=""
      />
    ),
  },
  {
    photo: (
      <StaticImage
        src="../../assets/images/work/misc/notorious-website.png"
        layout="fullWidth"
        placeholder="blurred"
        alt=""
      />
    ),
  },
  {
    photo: (
      <StaticImage
        src="../../assets/images/work/misc/aldo-logit.jpg"
        layout="fullWidth"
        placeholder="blurred"
        alt=""
      />
    ),
  },
  {
    photo: (
      <StaticImage
        src="../../assets/images/work/misc/affirm-emails.png"
        layout="fullWidth"
        placeholder="blurred"
        alt=""
      />
    ),
  },
]

const Container = styled.div`
  margin-left: -17.5vw;
  margin-right: -17.5vw;
  margin-bottom: -${spacing.large};

  ${largerThan.small`
    margin-left: -12.5vw;
    margin-right: -12.5vw;
  `};

  ${largerThan.medium`
    margin-left: -15vw;
    margin-right: -15vw;
  `};

  ${largerThan.large`
    margin-left: -16rem;
    margin-right: -16rem;
  `};

  ${largerThan.xLarge`
    margin-left: -20rem;
    margin-right: -20rem;
  `};
`

const Inner = styled.div`
  display: grid;
  grid-gap: ${spacing.medium};
  grid-auto-flow: dense;
  grid-template-columns: repeat(3, 1fr);

  ${largerThan.small`
    grid-gap: ${spacing.large};
  `};

  ${largerThan.medium`
    grid-template-columns: repeat(4, 1fr);
  `};

  ${largerThan.large`
    grid-template-columns: repeat(6, 1fr);
  `};
`

const Item = styled.figure`
  position: static;

  ${smallerThan.medium`
    &:nth-child(3n + 2) {
      transform: translateY(${spacing.large});
    }
  `};

  ${largerThan.medium`
    &:nth-child(2n + 1) {
      transform: translateY(${spacing.xxLarge});
    }
    
    &:nth-child(-n+2) {
      display: none; /* Hide last 2 */
    }
  `};

  ${largerThan.large`
    &:nth-child(2n + 1) {
      transform: translateY(${spacing.xxxLarge});
    }

    &:nth-child(-n+2) {
      display: block;
    }
  `};
`

const Photo = styled.div`
  border-radius: ${borderRadius};
  overflow: hidden;
  transform: translate3d(0, 0, 0);

  > * {
    ${cover('absolute')};
  }

  &:after {
    content: '';
    display: block;
    padding-bottom: 75%;
  }
`

const Gallery = () => (
  <Container>
    <Inner>
      {ITEMS.map((item, i) => (
        <Item key={i}>
          <Photo>{item.photo}</Photo>
        </Item>
      ))}
    </Inner>
  </Container>
)

export default Gallery
